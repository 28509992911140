



















import Vue, { VueConstructor } from 'vue'
import { Key } from 'ts-key-enum';

export default (Vue as VueConstructor<Vue & {
  $refs: any,
}>).extend({
  props: {
    value: String,
    placeholder: String,
    max: Number,
    format: Function,
    isActive: Boolean,
  },
  data() {
    return {
      // content: this.value,
    }
  },
  methods: {
    handleKeydown(e: KeyboardEvent) {
      if (e.key == Key.Tab) {
        // console.log('emitiendo evento');
        this.$emit('tab-pressed', e);
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      const numbers = ['1','2','3','4','5','6','7','8','9','0'];
      const maxLength = this.max || 6;

      if (e.key === Key.Backspace && this.content.length > 0) {
        this.content = this.content.slice(0, -1);
      } else if (numbers.includes(e.key) && this.content.length < maxLength) {
        this.content = this.content + e.key;
      }
      // this.$emit('input', this.content);
      // console.log('textbox content:', this.content);
    },
  },
  computed: {
    content: {
      get(): string {
        return this.value
      },
      set(value: string): void {
        this.$emit('input', value);
      },
    },
    formattedContent(): string {
      if (this.format === undefined) return this.content;
      return this.format(this.content);
    },
    isFocused() {
      return document.activeElement === this.$refs.wrapper?.$el;
    }
  },
  // watch: {
  //   content(currentValue: string) {
  //     this.$emit('input', currentValue);
  //   }
  // }
})
